.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spin-circle {
  background-image: -moz-radial-gradient(farthest-corner at 45px 45px, purple 0%,  purple 20%, red 100%);
  background-image: -webkit-radial-gradient(farthest-corner at 45px 45px, purple 0%,  purple 20%, red 100%);
  background-image: radial-gradient(farthest-corner at 45px 45px, purple 0%,  purple 20%, red 100%);
  
  border-radius: 50%;
  width: 200px;
  height: 200px;
  
  animation-name: spin; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: linear;
}


img.resize {
  width: 100%;
  height: auto;
}

.layout.horizontal {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.layout.horizontal, .layout.vertical {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
} 

.layout.center, .layout.center-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.layout.vertical {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.layout.wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex {
  -ms-flex: 1 1 0.000000001px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0.000000001px;
  flex-basis: 0.000000001px;
}

.hidden {
  display:none;
}


h1 {
  margin: 16px 0;
  color: white;
  font-size: 22px;
}

.username {
  color: #FF88FF;
}

.sword-guy {
  transition: top 1s ease, left 1s ease;
}
.small-map {
  transition: top 1s ease, left 1s ease;
}

a {
  color: white;
}

.clickable {
  cursor: pointer;
}

.linkbutton {
    background:none!important;
     border:none; 
     padding:0!important;
    
    font-family:arial,sans-serif; 
     color:#069;
     cursor:pointer;
}
.linkbutton:hover{
  text-decoration:underline;
}

.nocolorlinkbutton {
  background:none!important;
  border:none; 
  padding:0!important;
  cursor:pointer;
}
.nocolorlinkbutton:hover{
  text-decoration:underline;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.card {
  margin: 10px;
  padding: 10px;
  color: white;
  border-radius: 5px;
  background-color: #000;
  box-shadow: 0 1px 5px 0  rgba(255, 255, 255, 0.14), 0 1px 5px 0 rgba(255, 255, 255, 0.12), 0 1px 5px 0 rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.2);
}

/* small screen */

@media (max-width: 1024px) {
  .card {
    margin: 5px;
    padding: 5px;
    color: white;
    border-radius: 5px;
    background-color: #000;
    box-shadow: 0 1px 5px 0  rgba(255, 255, 255, 0.14), 0 1px 5px 0 rgba(255, 255, 255, 0.12), 0 1px 5px 0 rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.2);
    }
}      

